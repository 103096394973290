$font-family: inherit;
$color-primary: #6838D3;
$color-error: #FF0000;
$color-normal: #ffffff;
$color-selected: #F6F1FB;

@mixin baseInputStyle {
    font-family: $font-family;
    font-size: 14px;
    font-weight: 600;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 25px;
    padding-bottom: 16px;
    background-color: $color-normal;
}

@mixin baseLiStyle {
    height: 40px;
    width: auto;
    cursor: pointer;
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    margin: 0px -12px 0px;
}

.customSelectInputShowTitleNormalStyle {
    position: absolute;
    top: 10px !important;
    left: 20px !important;
    font-family: $font-family;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
}

.customSelectinputShowTitleSelectStyle {
    @extend .customSelectInputShowTitleNormalStyle;
    color: $color-primary !important;
}

.customSelectinputShowTitleErrorStyle {
    @extend .customSelectInputShowTitleNormalStyle;
    color: $color-error;
    left: unset !important;
    top: unset !important;
    bottom: 3px !important;
    right: 14px !important;
}

.dropdownBaseStyle {
    width: 100%;
    height: auto;
    border-radius: 32px;
    @include baseInputStyle;
}

.dropdownSelectedStyle {
    @extend .dropdownBaseStyle;
    border: 1px solid $color-primary;
}

.dropdownErrorStyle {
    @extend .dropdownBaseStyle;
    border: 1px solid $color-error;
}

.dropdownNormalStyle {
    @extend .dropdownBaseStyle;
    border: 1px;
}

.customLiBaseStyle {
    @include baseLiStyle;
}

.customLiSelectedStyle {
    @extend .customLiBaseStyle;
    background-color: $color-selected;
    justify-content: center;
    border-radius: 8px;
}

.customLiNormalStyle {
    @extend .customLiBaseStyle;
}

.multiSelectDiv {
    width: 100%;
}

.multiSelectUl {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    border-radius: 8px;
    margin-bottom: 8px;
}
