$color-primary: #3c383e;
$color-secondary: #ffffff;
$color-bg: #ffffff;
$color-selected-bg: #F6F1FB;
$color-border: #E0E0E0;

$font-family-roca: "Roca";

@mixin inputTextStyle {
    font-family: inherit;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    letter-spacing: 0.2px;
}

@mixin h2Style {
    text-align: center;
    font-family: $font-family-roca;
    font-weight: 700;
    font-size: 16px;
    line-height: 23.9px;
    margin-bottom: 8px;
    letter-spacing: 0.2px;
    color: $color-primary;
}

.cellNormalStyle {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 8px;
    background-color: $color-bg;
    @include inputTextStyle;
    color: $color-primary;
}

.cellSelectedStyle {
    @extend .cellNormalStyle;
    background-color: $color-selected-bg;
}

.h2Style {
    @include h2Style;
}

.search-input {
    position: relative;
}

.search-span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
}

.search-img-size {
    width: 24px;
    height: 24px;
}

.search-input-style {
    @include inputTextStyle;
    width: 100%;
    height: 56px;
    border-radius: 32px;
    padding: 16px 20px;
    padding-left: 48px;
    margin-bottom: 12px;
    border: 1px solid $color-border;
}

.country-list {
    height: 420px;
}

.list-inner {
    overflow-y: auto;
    height: 100%;
}
