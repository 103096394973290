$emailOrPhoneNormalBtnStyle: (
  font-family: inherit,
  background-color: #EFE9FF,
  color: #6939d3,
  border-radius: 24px,
  height: 48px,
  font-size: 16px,
  font-weight: 600,
  letter-spacing: 0.2px,
  border: none,
  box-shadow: none,
  text-align: center,
  line-height: 48px,
  text-decoration: none
);


.emailOrPhoneNormalBtnStyle {
  @each $property, $value in $emailOrPhoneNormalBtnStyle {
    #{$property}: $value;
  }
}

.emailOrPhoneSelectBtnStyle {
  @extend .emailOrPhoneNormalBtnStyle;
  background-color: #6838d3;
  color: #ffffff;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
}

.inputNormalStyle {
  width: 100%;
  height: 62px !important;
  border: 1px solid transparent;
  border-radius: 32px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  color: #3c383e;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.selectNormalStyle {
  @extend .inputNormalStyle;
  text-align: left;
  cursor: pointer;
}

.selectErrorStyle {
  @extend .inputNormalStyle;
  text-align: left;
  cursor: pointer;
  border: 1px solid #FF0000;
}

.inputErrorStyle {
  @extend .inputNormalStyle;
  border-color: #ff0000;
}

.inputFocusStyle {
  @extend .inputNormalStyle;
  border-color: #6838d3 !important;
}

.inputShowTitle {
  position: absolute;
  top: 10px;
  left: 10px;
  font-family: inherit;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
}


.footText1 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.02em;
  /* Text */
  color: #404040;
}

.customSelect {
  /* WebKit (Chrome, Safari) */
  -webkit-appearance: none;
  /* Mozilla Firefox */
  -moz-appearance: none;
  /* remove default arrow in IE */
  appearance: none;
  /* customize arrow */
  // background-image: url(../img/arrow-down-icon.png); 
  background-image: none;
  background-position: calc(100% - 20px) center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  padding-right: 20px !important;
}

.customSelect2 {
  /* WebKit (Chrome, Safari) */
  -webkit-appearance: none;
  /* Mozilla Firefox */
  -moz-appearance: none;
  /* remove default arrow in IE */
  appearance: none;
  /* customize arrow */
  //   background-image: url('../img/arrow-down-icon.png'); 
  background-position: calc(100%) center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  margin-top: 6px;
}

.customSelectBgImg {
  /* WebKit (Chrome, Safari) */
  -webkit-appearance: none;
  /* Mozilla Firefox */
  -moz-appearance: none;
  /* remove default arrow in IE */
  appearance: none;
  /* customize arrow */
  // background-image: url('../img/vector.png'); 
  background-position: calc(100% - 12px) center;
  background-repeat: no-repeat;
  background-size: 19.5px 19.5px;
}


/* modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
}

.modal-container {
  background-color: #fff;
  width: 100%;
  max-width: 100%;
  height: 1080px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
  transform: translateY(100%);
  animation: slideIn 0.3s ease forwards;
}

.modal-content {
  padding: 20px;
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(50%);
  }
}


.inputShowTitleNormalStyle {
  position: absolute;
  top: 5px !important;
  left: 12px !important;
  font-family: inherit;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  color: #3c383e !important;
}

.inputShowTitleSelectedStyle {
  @extend .inputShowTitleNormalStyle;
  color: #6838D3 !important;
}

.inputShowTitleErrorStyle {
  @extend .inputShowTitleNormalStyle;
  color: red !important;
  position: absolute !important;
  left: unset !important;
  top: unset !important;
  bottom: -38px !important;
  right: 5px !important;
  text-align: right !important;
}

.submitButtonStyle {
  background-color: #b2f100;
  color: #066448;
  width: 100%;
  border-radius: 24px;
  height: 48px;
  font-family: inherit;
  font-size: 16px;
  line-height: 19.36px;
  font-weight: 600;
  align-content: center;
  outline: none;
  user-select: none;
}

.submitButtonStyle:hover,
.submitButtonStyle:active {
  background-color: #b2f100;
  color: #066448;
}


.circularImageContainer {
  width: 24px;
  height: 24px;
  right: 12px;
}