$font-roca: "Roca";
$font-inter: "Inter";

$color-primary: #3c383e; 
$color-secondary: #6838D3; 

@mixin modalTextStyle {
    font-family: $font-inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    display: block;
}

.customModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998;
}

.customModalDiv {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    border-radius: 24px 24px 0 0;
    padding: 24px;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
    z-index: 9999;
}

.customModalP {
    font-family: $font-roca;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-align: center;
    margin-bottom: 16px;
}

.customModalSpan {
    @include modalTextStyle; 
    color: $color-primary;
    margin-bottom: 8px;
}

.customMessage {
    @include modalTextStyle;
}

.customModalImg {
    width: 80px;
    height: 80px;
    margin: 0 auto 16px;
    display: block;
}

.customContactUS {
    text-decoration: none;
    font-family: $font-inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: center;
    color: $color-secondary;
}

.customFeedBack{
    @include modalTextStyle; 
}
