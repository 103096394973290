.machineItem-container {
    width: calc(299px);
    border-radius: 8px;
    padding: 12px;
    margin: 12px;
    background-color: #ffffff;
    position: relative;
}

.machineItem-topleftNormalBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background-color: #1a112a;
    border-radius: 8px 0 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    margin-right: 12px;
}

.machineItem-topleftSelectedBg {
    @extend .machineItem-topleftNormalBg;
    background-color: #6838d3;
}

.machineItem-size {
    display: flex;
    width: 280px;
}

.machineItem-image {
    width: 57px;
    height: 82px;
    border-radius: 4px;
    margin-right: 12px;
    object-fit: contain;
}

.machineItem-title {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    margin-bottom: 0;
    white-space: normal;
    overflow-wrap: break-word;
    word-break: break-word;
    width: 209px;
}

.machineItem-detail {
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    margin-bottom: 8px;
    white-space: normal;
    overflow-wrap: break-word;
    word-break: break-word;
    width: 209px;
}

.machineItem-span {
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #6838d3;
}

.machineItem-flexEnd {
    display: flex;
    justify-content: flex-end;
}

.machineItem-flexCenter {
    display: flex;
    align-items: center;
}

.machineItem-column {
    display: flex;
    flex-direction: column;
}

.machineItem-arrow{
    width: 20px;
    height: 20px;
}