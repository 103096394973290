$font-family: inherit;

$color-primary: #3e0093;
$color-secondary: #000000;
$color-button-bg: #6838d3;
$color-button-text: #ffffff;

@mixin dashboardTextStyle {
    font-family: $font-family;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    margin-bottom: 4px;
}

@mixin buttonStyle {
    background-color: $color-button-bg;
    color: $color-button-text;
    width: 100%;
    border-radius: 24px;
    height: 48px;
    font-family: $font-family;
    font-size: 16px;
    line-height: 19.36px;
    font-weight: 600;
    align-content: center;

    &:active {
        background-color: $color-button-bg;
    }

    &:focus {
        background-color: $color-button-bg;
    }
}

.accessCodeMT {
    margin-top: 136px;
}

.dashBoardRow {
    width: 100%;
    height: 80px;
    border-radius: 40px;
    background-color: #ffffff;
    padding: 16px 20px;
    margin-bottom: 16px;
    margin-left: 1px;
    align-items: center;
}

.dashBoardRowIcon {
    width: 48px;
    height: 48px;
    float: right;
    margin-right: 15px;
}

.dashBoardItemSpan {
    @include dashboardTextStyle;
    color: $color-primary;
}

.dashBoardItemText {
    @include dashboardTextStyle;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: $color-secondary;
}

.dashBoardFindMachine {
    @include buttonStyle;
    color: #ffffff;
    &:hover {
        background-color: $color-button-bg; 
        color: #ffffff; 
    }
    &:active {
        background-color: $color-button-bg !important; 
    }
}

.dashBoardBtnTM {
    margin-top: 125px;
    margin-bottom: 20px;
}

.col-8.offset-col-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
