$color-text: #B0B0B0;
$color-text-hover: #000000;

$font-family: "Inter";

@mixin textButtonStyle {
    font-family: $font-family;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: center;
    color: $color-text;
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
    display: block;
    margin: 0 auto;

    &:hover,
    &:focus,
    &:active {
        color: $color-text-hover;
    }
}

.textBtn {
    @include textButtonStyle;
}
