$font-family: Inter;

$color-primary: #6838D3;
$color-error: red;

@mixin accessFontStyle {
    font-family: $font-family;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-align: center;
    margin-bottom: 8px;
    display: block;
}

.accessSpan {
    @include accessFontStyle; 
}

.accessa {
    @include accessFontStyle; 
    display: unset !important;
    font-weight: 500;
    text-decoration: none;
    color: $color-primary; 
}

.accessCodeMT {
    margin-top: 136px;
}

.accessErrorLabel {
    color: $color-error; 
    float: right;
    font-size: 12px;
    font-family: inherit;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.73px;
}
