.vendingMachinPage {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(246, 241, 251, 0.8);
    padding-bottom: 20px;
    margin-right: 0; 
}



.vendingMachinPageSpan {
    font-family: Roca;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-align: center;
    display: block;
    margin-top: 24px;
}

.vendingMachineItemList {
    display: flex;
    overflow-x: scroll;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.cusContainer {
    display: flex;
    flex-direction: column;
    height: 100vh; 
    padding: 0;
    margin: 0;
}

.vendingMachinPage {
    flex: 1; 
    position: relative; 
}

.map-container {
    height: 60vh; 
    width: 100%; 
}