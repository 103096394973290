.textPrimary {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 8px;
    letter-spacing: 0.2px;
    text-align: center;
    color: #3c383e;
}

.headImageSizeStyle {
    // margin-top: 109.18px;
    width: 109.18px;
    height: 64px;
}

.headTitleSizeStyle{
    font-family: Roca;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px; 
    letter-spacing: 0.2px;
    color: #3c383e;
    text-align: center;
}

.headTextSizeStyle{
    font-family: inherit;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    letter-spacing: 0.2px;
    color: #3c383e;
    text-align: center;
    margin-bottom: 8px;
}