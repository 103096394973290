.d-flex {
    display: -ms-flexbox !important;
    display: flex !important
}

.footer-menu-mobile .o-footer {
    padding: 0
}

.footer-menu-mobile .o-footer .nav-link {
    font-weight: 400
}

@media (max-width: 991.98px) {
    .o-footer {
        padding: 0 2.4rem
    }
}

@media (max-width: 767.98px) {
    .o-footer {
        padding: 0
    }
}

.footer-site {
    margin-top: 4rem;
    margin-bottom: 8rem
}

@media (max-width: 991.98px) {
    .footer-site {
        margin-bottom: 4rem
    }
}

@media (max-width: 767.98px) {

    .footer-site>.container,
    .footer-site>.container-lg {
        padding: 0
    }
}

.p-l-8 {
    padding-left: 8rem
}

@media (max-width: 1199.98px) {
    .p-l-8 {
        padding-left: 2.4rem
    }
}

@media (max-width: 991.98px) {
    .p-l-8 {
        padding-left: 1.2rem
    }
}

.p-r-8 {
    padding-right: 8rem
}

@media (max-width: 1199.98px) {
    .p-r-8 {
        padding-right: 2.4rem
    }
}

@media (max-width: 991.98px) {
    .p-r-8 {
        padding-right: 1.2rem
    }
}

.o-footer-row {
    padding-top: 2.4rem;
    border-top: 1px solid #d9cdf4;
    color: #3c3a3f;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important
}

.flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important
}

.flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important
}

.justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important
}

@media (max-width: 767.98px) {
    .o-footer-content {
        -ms-flex-pack: center !important;
        justify-content: center !important
    }
}

@media (min-width: 768px) {
    .footer-menu-mobile {
        display: none
    }
}

.footer-menu-mobile .o-footer {
    padding: 0
}

.footer-menu-mobile .o-footer .nav-link {
    font-weight: 400
}


@media (max-width: 767.98px) {
    .o-footer--left {
        width: 100%
    }
}

.o-footer--left .logo-link-footer {
    height: 54px;
    width: 116px
}

.o-footer--left .logo-link-footer img {
    aspect-ratio: 116/57
}

.o-footer--left .content-copy-right-footer {
    margin-left: 4.7rem
}

@media (max-width: 991.98px) {
    .o-footer--left .content-copy-right-footer {
        margin-left: 1.6rem
    }
}

@media (max-width: 767.98px) {
    .o-footer--left .content-copy-right-footer {
        margin-left: 0;
        margin-top: 2.4rem;
        width: 100%;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 2.4rem
    }
}

.o-footer--left .content-copy-right-footer {
    margin-left: 4.7rem;
}

@media (max-width: 991.98px) {
    .o-footer--left .content-copy-right-footer {
        margin-left: 1.6rem;
    }
}

@media (max-width: 767.98px) {
    .o-footer--left .content-copy-right-footer {
        margin-left: 0;
        margin-top: 2.4rem;
        width: 100%;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 2.4rem;
    }
}

/*# sourceMappingURL=App.css.map */

@media (max-width: 767.98px) {
    .o-footer--right {
        width: 100%;
        -ms-flex-pack: center;
        justify-content: center;
        display: -ms-flexbox;
        display: flex
    }
}

.o-footer--right ul {
    margin: 0;
    padding: 0
}

.o-footer--right .menu-item {
    color: #400093;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: inline-block;
    list-style: none
}

.o-footer--right .menu-item .nav-link {
    color: currentcolor
}

.o-footer--right .menu-item .nav-link:hover {
    color: currentcolor;
    text-decoration: underline
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
    color: #400094;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .nav-link {
        transition: none
    }
}

.nav-link:focus,
.nav-link:hover {
    color: #330076
}

.nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default
}

.o-footer--left .logo-link-footer {
    height: 54px;
    width: 116px;
}

.footImg {
    height: 54px;
    width: 116px;
    // aspect-ratio: 116/57
}

.container,
.container-lg {
    --bs-gutter-x: 1.6rem;
    max-width: 1440px
}

@media (min-width: 768px) {

    .container,
    .container-lg {
        --bs-gutter-x: 1.6rem
    }
}

@media (min-width: 1200px) {

    .container,
    .container-lg {
        --bs-gutter-x: 3.2rem
    }
}

@media (min-width: 1440px) {

    .container,
    .container-lg {
        max-width: 1440px
    }
}

.container-small {
    width: 100%;
    --bs-gutter-x: 2.4rem
}

@media (min-width: 768px) {
    .container-small {
        --bs-gutter-x: 16.5rem
    }
}

@media (min-width: 1200px) {
    .container-small {
        --bs-gutter-x: 26rem
    }
}

@media (min-width: 1440px) {
    .container-small {
        max-width: 100%
    }
}

.container-wide {
    width: 100%;
    padding-right: var(--bs-gutter-x, 12px);
    padding-left: var(--bs-gutter-x, 12px);
    margin-right: auto;
    margin-left: auto;
    width: 1440px;
    max-width: 100%
}

@media (max-width: 1199.98px) and (min-width:576px) {
    .container-wide {
        max-width: 540px
    }
}

@media (max-width: 1199.98px) and (min-width:768px) {
    .container-wide {
        max-width: 720px
    }
}

@media (max-width: 1199.98px) and (min-width:992px) {
    .container-wide {
        max-width: 960px
    }
}

@media (max-width: 1199.98px) and (min-width:1200px) {
    .container-wide {
        max-width: 1140px
    }
}

@media (max-width: 1199.98px) and (min-width:1366px) {
    .container-wide {
        max-width: 1320px
    }
}

@media (max-width: 1199.98px) and (min-width:1440px) {
    .container-wide {
        max-width: 1440px
    }
}

.container-lg {
    padding-right: 1.6rem;
    padding-left: 1.6rem
}

@media screen and (min-width: 768px) {
    .container-lg {
        padding-right: 2.4rem;
        padding-left: 2.4rem
    }
}

@media screen and (min-width: 1120px) {
    .container-lg {
        padding-right: 6.4rem;
        padding-left: 6.4rem
    }
}

.container-sm {
    width: 100%;
    max-width: 974px;
    --bs-gutter-x: 1.6rem
}

.fancybox__container {
    --fancybox-color: #dbdbdb;
    --fancybox-hover-color: #fff;
    --fancybox-bg: rgba(24, 24, 27, 0.98);
    --fancybox-slide-gap: 10px;
    --f-spinner-width: 50px;
    --f-spinner-height: 50px;
    --f-spinner-color-1: rgba(255, 255, 255, 0.1);
    --f-spinner-color-2: #bbb;
    --f-spinner-stroke: 3.65;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    direction: ltr;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #f8f8f8;
    -webkit-tap-highlight-color: transparent;
    overflow: visible;
    z-index: var(--fancybox-zIndex, 1050);
    outline: 0;
    transform-origin: top left;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    overscroll-behavior-y: contain
}

.fancybox__footer {
    padding: 0;
    margin: 0;
    position: relative
}

.fancybox__footer .fancybox__caption {
    width: 100%;
    padding: 24px;
    opacity: var(--fancybox-opacity, 1);
    transition: all .25s ease
}

.is-compact .fancybox__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
    background: rgba(24, 24, 27, .5)
}

.is-compact .fancybox__footer .fancybox__caption {
    padding: 12px
}

.is-idle.is-compact .fancybox__footer {
    pointer-events: none;
    animation: .15s ease-out both f-fadeOut
}


@media (max-width: 767.98px) {
    .o-footer--left .content-copy-right-footer {
        margin-left: 0;
        margin-top: 2.4rem;
        width: 100%;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 2.4rem
    }
}