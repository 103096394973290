body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.inputNormalStyle:focus{
  border-color: rgb(104, 56, 211) !important;
  box-shadow: none !important;
}

.customSelect:focus{
  border-color: rgb(104, 56, 211) !important;
  box-shadow: none !important;
}

@media (min-width: 768px) {
  .o-footer-content{
    display: flex;
    justify-content: space-between!important;
  }
  .o-footer--left {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .footImg{
    height: 54px;
    width: 116px;
  }
  .content-copy-right-footer{
    text-align: center;
    margin-left: 0!important;
  }
}

@media (max-width: 768px) {
  .o-footer--left {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footImg{
    height: 54px;
    width: 116px;
  }
}

.customSelect2 {
  background-image: url(./img/arrow-down-icon.png);
  background-position: calc(100% - 20px) center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.customSelect {
  background-image: url(./img/arrow-down-icon.png) !important; 
  background-position: calc(100% - 18px) center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}


.customSelectBgImg {
  background-image: url(./img/vector.png); 
  background-position: calc(100% - 14px) center;
  background-repeat: no-repeat;
  background-size: 19.5px 19.5px;
}