$font-family: inherit;

$color-primary: #b2f100;
$color-secondary: #066448;
$color-hover: #f0ff00;
$color-disabled: rgba(0, 0, 0, 0.2); 

@mixin buttonStyle {
    width: 100%;
    border-radius: 24px;
    height: 48px;
    font-family: $font-family;
    font-size: 16px;
    line-height: 19.36px;
    font-weight: 600;
    align-content: center;
}

.confirmBtn {
    background-color: $color-primary !important;
    color: $color-secondary;
    @include buttonStyle;

    &:hover {
        background-color: $color-hover;
    }

    &:active {
        background-color: $color-primary;
    }

    &:disabled {
        background-color: $color-disabled !important;
        cursor: not-allowed;
    }
}
