$color-primary: #b2f100;
$color-secondary: #066448;
$color-bg: #ffffff;
$color-progress-bg: #EBFBBF;

$font-family: "Inter";

@mixin buttonStyle {
    margin-top: 16px;
}

.machineDetailImg {
    display: block;
    margin: auto;
    width: 240px;
    height: 360px;
    border-radius: 8px;
    background-color: $color-bg;
    margin-bottom: 16px;
}

.progress-container {
    position: relative;
}

.background-box {
    position: relative;
    width: 100%;
    height: 48px;
    background-color: $color-progress-bg;
    border-radius: 24px;
    margin-top: 26px;
}

.progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: $color-primary;
    border-radius: 24px;
    color: $color-secondary;
    font-family: $font-family;
    font-size: 14px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
    animation: progressAnimation 0.8s ease-in-out forwards;
    justify-content: center;
    align-items: center;
    animation-iteration-count: 1; 
}

@keyframes progressAnimation {
    from {
            width: 0%;
    }
    100% {
        width: 100%;
    }
}

.btn {
    @include buttonStyle;
}

.checkbox-container {
    display: flex;
    justify-content: center; 
    align-items: center; 
}

.checkbox-container input{
    display: inline-block;
    vertical-align: middle;
    margin-top: 20px;
}

.checkbox-container label{
    padding-left: 4px;
    cursor: pointer;
    margin: 0;
    vertical-align: middle;
}