:root {
    --f-button-width: 40px;
    --f-button-height: 40px;
    --f-button-border: 0;
    --f-button-border-radius: 0;
    --f-button-color: #374151;
    --f-button-bg: #f8f8f8;
    --f-button-hover-bg: #e0e0e0;
    --f-button-active-bg: #d0d0d0;
    --f-button-shadow: none;
    --f-button-transition: all 0.15s ease;
    --f-button-transform: none;
    --f-button-svg-width: 20px;
    --f-button-svg-height: 20px;
    --f-button-svg-stroke-width: 1.5;
    --f-button-svg-fill: none;
    --f-button-svg-filter: none;
    --f-button-svg-disabled-opacity: 0.65
}


:root body {
    font-family: Inter, Arial, sans-serif;
    font-size: 1.8rem;
    line-height: 1.5555555556;
    font-weight: 400;
    color: #1c122c;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f6f3fc
}

:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: rgb(64, 0, 148);
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #2a0763;
    --bs-primary: rgb(64, 0, 148);
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #2a0763;
    --bs-primary-rgb: 64, 0, 148;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 42, 7, 99;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 42, 7, 99;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #2a0763;
    --bs-body-bg: #fff
}

:root {
    --f-spinner-width: 36px;
    --f-spinner-height: 36px;
    --f-spinner-color-1: rgba(0, 0, 0, 0.1);
    --f-spinner-color-2: rgba(17, 24, 28, 0.8);
    --f-spinner-stroke: 2.75
}

:root {
    font-size: 62.5%
}

body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent
}

.d-flex {
    display: -ms-flexbox !important;
    display: flex !important
}

.flex-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important
}

.backgroundPrimary {
    background-color: #F6F1FB !important
}

.textSecondary {
    color: #888888;
}