$font-roca: "Roca";

@mixin modalTextStyle {
    font-family: $font-roca;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-align: center;
    margin-bottom: 16px;
}

.inputModalAllStyle {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9998;
}

.inputModalStyle {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25vh;
    background-color: #ffffff;
    border-radius: 24px 24px 0 0;
    padding: 24px;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
    z-index: 9999;
}

.inputModalPStyle {
    @include modalTextStyle;
}
